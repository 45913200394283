import 'flickity/dist/flickity.pkgd.js';


var hideHeader = 300;

export default {
  init() {
    // Hide sticky header at initial load to avoid scroll back in mac os
    $('body > header').addClass('d-none');
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    servicesSlider();
    testimonialSlider();
  },
};

$(window).on('resize', function () {
  $('.our-services-section .services-wrp, .testimonials-section .testimonials-wrp').flickity('resize');
});

$(window).on('load', function () {
  $('.our-services-section .services-wrp, .testimonials-section .testimonials-wrp').flickity('resize');
});

function servicesSlider() {
  $('.our-services-section .services-wrp').flickity({
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: false,
  });
}

function testimonialSlider() {
  $('.testimonials-section .testimonials-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: false,
  });

  // append arrows to the inner-wrp
  $('.testimonials-section .section-inner-wrp .testimonials-wrp .flickity-button').appendTo('.testimonials-section .section-inner-wrp');
}

// Show sticky header after scroll and set height to sticky-overlay
$(window).scroll(function () {
  let scroll = getCurrentScroll();
  if (scroll < hideHeader) {
    $('body > header').addClass('d-none');
  } else {
    $('body > header').removeClass('d-none');
    $('.sticky-overlay').outerHeight($('.header').outerHeight());
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// Show sticky header after scroll and set height to sticky-overlay